import { Button, Result } from "antd";


const UnsubscribeError = () => {


    return (
        <Result
            status="500"
            title="We are facing some issues"
            subTitle="We're sorry we could'nt unsubscribe, please try again after some time or contact us at contact@playmoments.app"
            //icon={<img src="/images/unsubscribe.jpg" />}
            extra={
                <Button
                    onClick={() => {
                        window.location.href = '/'
                    }}
                    type="primary">Home</Button>}
        />
    )


}


export default UnsubscribeError;