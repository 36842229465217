import { Button, Result } from "antd"
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { AppLinks, AppRoutes } from "../../config/constants";

import './index.scss'

const ConfirmUnsubscribe = () => {

    let [searchParams] = useSearchParams();
    const [pageState, setPageState] = useState('')

    const navigate = useNavigate();

    const unsubscribeAsync = async () => {
        try {

            const unsubscribeToken = searchParams.get('ref')

            if (typeof unsubscribeToken !== 'string' || unsubscribeToken.trim().length === 0) {
                console.log('no unsubscribe token found in query string')
                return;
            }

            const postData = {
                ref: unsubscribeToken
            }

            const unsubscribeLink = AppLinks.unsubscribe

            setPageState('processing')

            const response = await fetch(unsubscribeLink, {
                method: 'POST',
                // mode: "no-cors", // no-cors, *cors, same-origin
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(postData)
            })

            const text = await response.text()


            console.log('text', text)

            const parsedResponse = JSON.parse(text) as {
                code: string,
                data: {
                    email: string,
                    existing: boolean
                }
            } | undefined

            console.log('parsed response', parsedResponse)

            setPageState('')

            if (parsedResponse?.code === '0') {
                navigate(`/${AppRoutes.unsubscribeSuccess}`);
            } else {
                navigate(`/${AppRoutes.unsubscribeError}`);
            }

        } catch (error) {
            console.log('error unsubscribing', error)
            setPageState('error')
        }
    }


    return (
        <div className="confirm-unsubscribe-container">
            <Result
                status="info"
                title="Are you sure you want to unsubscribe?"
                subTitle="In case you need to contact us for something please email us at contact@playmoments.app"
                icon={<img className="image" src="images/confirm-unsubscribe.jpg" />}
                extra={
                    <div className="buttons-container">
                        <Button
                            className="btn-no"
                            disabled={pageState === 'processing'}
                            onClick={() => {
                                window.location.href = '/'
                            }}
                        >No</Button>
                        <Button
                            loading={pageState === 'processing'}
                            onClick={unsubscribeAsync}
                        >Yes</Button>
                    </div>
                }
            />
        </div>
    )


}


export default ConfirmUnsubscribe