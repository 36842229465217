import { Button, Result } from "antd";


const UnsubscribeSuccess = () => {


    return (
        <Result
            status="info"
            title="You have been unsubscribed"
            subTitle="We're sorry to see you leave, in case you need to contact us for something please email us at contact@playmoments.app"
            icon={<img src="images/unsubscribe.jpg" />}
            extra={
                <Button
                    onClick={() => {
                        window.location.href = '/'
                    }}
                    type="primary">Home</Button>}
        />
    )


}


export default UnsubscribeSuccess;