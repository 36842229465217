import { Button, Result } from "antd";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { AppRoutes } from "../../config/constants";


const Unsubscribe = () => {

    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation()
    const [success] = useQueryParam('success', StringParam);
    const [type] = useQueryParam('type', StringParam);
    //const [email] = useQueryParam('email', StringParam);

    // if (success === '1') {
    //     console.log('success', email)
    // }

    useEffect(() => {

        if (type === 'unsubscribe') {

            const unsubscribeToken = searchParams.get('ref')

            if (typeof unsubscribeToken === 'string' && unsubscribeToken.length > 0) {

                navigate({
                    pathname: `/${AppRoutes.confirmUnsubscribe}`,
                    search: location.search,
                });

            }

        }

    }, [type])

 

    if (success === '1') {
        return (
            <Result
                status="info"
                title="You have been unsubscribed"
                subTitle="We're sorry to see you leave, in case you need to contact us for something please email us at contact@playmoments.app"
                icon={<img src="images/unsubscribe.jpg" />}
                extra={
                    <Button
                        onClick={() => {
                            window.location.href = '/'
                        }}
                        type="primary">Home</Button>}
            />
        )
    } else {
        return (
            <Result
                status="500"
                title="We are facing some issues"
                subTitle="We're sorry we could'nt unsubscribe, please try again after some time or contact us at contact@playmoments.app"
                //icon={<img src="/images/unsubscribe.jpg" />}
                extra={
                    <Button
                        onClick={() => {
                            window.location.href = '/'
                        }}
                        type="primary">Home</Button>}
            />
        )
    }


}


export default Unsubscribe;